import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Educating from '../../assets/img/Educating.jpg';
import Community from '../../assets/img/Community.jpg';
import Community2 from '../../assets/img/Community2.jpg';
import Educating2 from '../../assets/img/Educating2.jpg';
import Age from '../../assets/img/Age.jpg';
import Smile1 from '../../assets/img/smile1.jpg';
import Smile2 from '../../assets/img/smile2.jpg';
import Smile3 from '../../assets/img/smile3.jpg';
import Smile4 from '../../assets/img/smile4.jpg';


export default function OurActivities() {
  return (
    <Wrapper id="ouractivites">
      <div className="whiteBg" style={{
        marginTop: "2.5rem",
        marginBottom: "10px",

      }}>
        <div className="container">
          <HeaderInfo className="text-center">
            <h1 className="font40 extraBold" style={{
              marginTop: "2rem",
              marginBottom: "2rem",
              color: "#954F37",
            }}>Our Activities</h1>
          </HeaderInfo>
          <div className="row">
            <h3 style={{fontWeight:"700",marginBottom:"1rem",color:"#954F37"}}>Healthcare Initiatives</h3>
            <p>
            At Karmana Foundation, we believe that a healthy community is the foundation of a prosperous society. Our healthcare initiatives are designed to reach the most vulnerable, providing them with the care and knowledge they need to thrive.
            </p>
            <ul style={{marginTop:"1rem"}} className="ul-activities">
              <li>
              - <u>Women's Wellness Campaigns and Camps:</u> We prioritize the health of women, recognizing their critical role in the fabric of society. Our programs focus on preventive care, reproductive health, and mental wellness, ensuring women have the resources to lead healthy lives.
              </li>
              <li>
              - <u>Vital Health Checkup camps:</u> Regular health checkups are essential for early detection and prevention of diseases. We organize camps offering free screenings for blood pressure, diabetes, and other common conditions, making healthcare accessible to all.
              </li>
              <li>
              - <u>Awareness Initiatives:</u> Knowledge is power. Our awareness campaigns educate communities on important health topics, from hygiene practices to the importance of vaccinations, driving positive behavioral changes.
              </li>
              <li>
              - <u>Nutrition Supply for Underprivileged Sections:</u> No one should go hungry. We provide nutritious meals and supplements to those in need, focusing on children and the elderly, to combat malnutrition and promote healthy development.
              </li>
            </ul>
          </div>
          <br/>
          <div className="row">
            <h3 style={{fontWeight:"700",marginBottom:"1rem",color:"#954F37"}}>Technical Skill Development</h3>
            <p>
            In today's digital world, technical skills are a ladder to economic independence and growth. Our programs are tailored to equip individuals with the knowledge and tools to succeed in the tech-driven economy.
            </p>
            <ul style={{marginTop:"1rem"}} className="ul-activities">
                <li>
                - <u>Data Analytics Training:</u> We offer courses in data analytics, teaching participants how to interpret and use data effectively, a skill in high demand across industries.
                </li>
                <li>
                - <u>CRM Development Programs:</u> Customer Relationship Management (CRM) systems are vital for businesses of all sizes. Our training sessions focus on developing CRM solutions, empowering participants to support businesses in managing customer interactions.
                </li>
                <li>
                - <u>ERP Development Initiatives:</u> Enterprise Resource Planning (ERP) systems help businesses streamline their processes. We provide hands-on training in ERP development, preparing participants for careers in this essential field.
                </li>
            </ul>
          </div>
          <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem"
            }}>
          <div className="carousel-div">
                <Carousel showThumbs={false} infiniteLoop={true} autoPlay>
                  <div>
                      <img src={Community} alt="Community" />
                  </div>
                  <div>
                      <img src={Educating} alt="Educating" />
                  </div>
                  <div>
                      <img src={Community2} alt="Community2" />
                  </div>
                  <div>
                      <img src={Educating2} alt="Educating2" />
                  </div>
                  <div>
                      <img src={Age} alt="Community2" />
                  </div>
                  <div>
                      <img src={Smile1} alt="Community2" />
                  </div>
                  <div>
                      <img src={Smile2} alt="Community2" />
                  </div>
                  <div>
                      <img src={Smile4} alt="Community2" />
                  </div>
                </Carousel>
              </div>
            </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  maring-top: 60px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
