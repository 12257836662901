import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import Logo from "../../assets/img/logo.png"

export default function Footer() {
  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container textCenter">
          <div className="flexSpaceCenter" style={{ padding: "30px 5px" }}>
            <div className=" row">
            <div className="col-md-4">
                  <img src={Logo} alt="logo" style={{ width: "50%", height: "auto" }} />
                  <p className="whiteColor font13" style={{ marginTop: "10px" }}>
                  Our mission is to enhance the health and wellness of communities while empowering individuals through technical skill development. We believe in creating a world where everyone has access to healthcare and the opportunity to thrive in the digital era.
                  </p>
                </div>
                <div className="col-md-4 " style={{marginLeft:"auto"}}>
                  <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "-5px" }}>
                    Address
                  </h1>
                  <p className="whiteColor font13" style={{ marginTop: "10px" }}>
                    <span className="font13" style={{
                      fontWeight: "700",
                      color: "#954F37",
                    }}>Karmana Foundation</span><br />
                    83, Laxmi Nagar, <br/>
                    Garkheda, Aurangabad (Sambhaji Nagar)<br/>
                    <i className="bi bi-envelope" /> {' '} <a href="mailto:info@karmanafoundation.org" className="whiteColor" style={{ textDecoration: "none"}}>info@karmanafoundation.org</a><br/>
                    <i className="bi bi-telephone" /> {' '}<a href="tel:+918796663614" className="whiteColor" style={{ textDecoration: "none"}}>+91-8796663614</a><br/>
                    <br/>
                    <br/>
                  </p>
                  </div>
                  <div className="col-md-4" style={{marginRight:"auto"}}>
                    <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "-5px" }}>
                      Quick Links
                    </h1>
                    <ul className="whiteColor font13" style={{ marginTop: "10px" }}>
                      <li className="pointer my-1">
                        <i class="bi bi-geo-alt-fill"/>{' '}<a href="https://maps.app.goo.gl/Md1rA3WXmqT6gAr86" className="whiteColor" style={{ textDecoration: "none"}}>Google Maps</a>
                      </li>
                      <li className="pointer my-1">
                        <i className="bi bi-facebook" /> {' '}<a href="/" className="whiteColor" style={{ textDecoration: "none"}}>Facebook</a>
                      </li>
                      <li className="pointer my-1">
                        <i className="bi bi-instagram" /> {' '}<a href="/" className="whiteColor" style={{ textDecoration: "none"}}>Instagram</a>
                      </li>
                    </ul>
                    </div>
                  </div>
            </div>
            
        </div>
        <div className="container textCenter">
          <InnerWrapper className="flexSpaceCenter1" style={{ padding: "10px 0" }}>
            <StyleP className="whiteColor font13">
              © {2023} - <span className="font13"><b>Karmana Foundation</b>.</span> All Right Reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
