import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets

import Community from '../../assets/img/Community.jpg';
import Educating2   from '../../assets/img/Educating2.jpg';
import Children from '../../assets/img/Children.jpg';
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Link } from "react-scroll";
import { scroller } from "react-scroll";

export default function Header() {
  return (
    <Wrapper id="home"
      style={{
        backgroundImage: `url(${Children})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        height: "100vh",
        width: "100%",
        zIndex: 1,
        paddingTop: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",

      }}
    >
      <div className="flexCenter"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          flexDirection: "column",
          backgroundColor: "#ffffffd6",
          padding: "0.9rem",
        }}
      >
          <h1 className="font60 text-center" 
            style={{
              color: "#954F37",
              fontWeight: "700",
              lineHeight: "1.2",
            }}>
            Welcome to Karmana Foundation
          </h1>
          <h4 className="text-center" style={{
            color: "#d29824",
            fontWeight: "600",
          }}>A beacon of hope and change dedicated to fostering a sustainable society</h4>

          <HeaderP className="font10 semiBold text-center" style={{
            padding: "15px 0 28px 0",
          }}>
          Our mission is to enhance the health and wellness of communities while empowering individuals through technical skill development. We believe in creating a world where everyone has access to healthcare and the opportunity to thrive in the digital era.
          </HeaderP>
            <FullButton
              title="Join Us"
              action={() =>
                scroller.scrollTo("joinus", {
                  smooth: true,
                  offset: -70,
                  duration: 300,
                })
              }
            />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 670px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 30px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
