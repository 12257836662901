import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
// Assets
import Partners from '../../assets/img/partners.jpg';
import hands from '../../assets/img/hands.jpg'

import { scroller } from "react-scroll";



export default function JoinUs() {
  return (
    <Wrapper id="joinus">
      <div className="lightBg" style={{
        marginTop: "30px",
        marginBottom: "10px",
      }}>
        <div className="container text-center" style={{paddingTop:"40px",paddingBottom:"40px"}}>
          <HeaderInfo>
            <h1 className="font40 extraBold" style={{color: "#954F37"}}
              >Join Us
            </h1>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" , marginTop:"10px"}}>
                <p>
                  Be part of our mission to build a healthier, more self-sufficient society. Whether you're looking to volunteer, donate, or participate in our programs, your support can make a world of difference. Together, we can create a sustainable future for all.
                </p>
          </div>
          <FullButton
                  title="Get Involved"
                  action={() =>
                    scroller.scrollTo("contact", {
                      smooth: true,
                      offset: -70,
                      duration: 300,
                    })
                  }
                  width={"10rem"}
              />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  maring-top: 60px;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
