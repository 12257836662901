import {React, useState} from "react";
import styled from "styled-components";
// Assets
import ContactImg from "../../assets/img/contact.jpeg";
import * as emailjs from "emailjs-com";
import team from   '../../assets/img/team.jpg'

export default function Contact() {
  const [formState, setFormState] = useState({});
  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
    const submitHandler = (e) => {
    e.preventDefault();
    if(formState.Name === "" || formState.Email === "" || formState.Message === "" || formState.Phone === "" ){
      alert("Please fill out all the fields");
      return;
    }

    var template_params = {
      name: formState.Name,
      email: formState.Email,
      phone: formState.Phone,
      message: formState.Message,
    };

    // YOUR EMAIL.JS API KEY 
    let API_KEY = "ucCD10bGWXmDseOS2";
    // YOUR EMAIL.JS TEMPLATE ID
    let TEMPLATE_ID = "template_f4ghybs";
    console.log(template_params);

    emailjs.send("service_7ictl5h", TEMPLATE_ID, template_params, API_KEY).then(
      function (response) {
        if (response.status === 200) {
          // self.showSuccessModal();
          alert("Your message has been sent successfully. We will contact you soon.");
          window.location.reload();
        } else {
          alert("Something went wrong. Please try again.");
        }
      },
      function (error) {
        alert("Something went wrong. Please try again.");
      }
    );
  };


  return (
    <Wrapper id="contact">
      <div className="">
        <div className="container" style={{
          marginTop: "30px",
          paddingBottom: "30px"
        }}>
          <HeaderInfo style={{
            paddingBottom:"0px"
          }}>
            <h1 className="font35 extraBold" style={{
              color: "#954F37",
            }}>Contact Us</h1>
            <p className="font13">
            Ready to make an impact? Contact us to learn more about our programs, how you can contribute, or to partner with us in our mission. Let's work together to build a brighter, healthier tomorrow.
            </p>
          </HeaderInfo>
          <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex flexCenter">
                  <img src={team} alt="contact" style={{ width: "100%"}} className="radius8" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form >
                <label className="font13">Name:</label>
                <input type="text" id="fname" name="Name" className="font20 " onChange={changeHandler} />
                <label className="font13">Email:</label>
                <input type="email" id="email" name="Email" className="font20 " onChange={changeHandler}/>
                <label className="font13">Phone number:</label>
                <input type="text" id="phone" name="Phone" className="font20 " onChange={changeHandler}/>
                <label className="font13">Message:</label>
                <input type="text" id="message" name="Message" className="font20 " onChange={changeHandler}/>
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Submit" className="pointer animate radius8" style={{ maxWidth: "170px" }} onClick={submitHandler}/>
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #954F37;
  background-color: #954F37;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #7f432e;
    border: 1px solid #7f432e;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









