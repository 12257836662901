import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";

import TopNavbar from "./components/Nav/TopNavbar";
import Header from "./components/Sections/Header";
import OurActivities from "./components/Sections/OurActivities";
import Footer from "./components/Sections/Footer"
import AboutUs from "./components/Sections/AboutUs";
import OurMission from "./components/Sections/OurMission";
import JoinUs from "./components/Sections/JoinUs";
import Contact from "./components/Sections/Contact";


export default function App() {
  return (
    <>
      <TopNavbar />
      <Header />
      <OurMission/>
      <AboutUs/>
      <OurActivities />
      <JoinUs/>
      <Contact/>
      <Footer />
    </>
  );
}

